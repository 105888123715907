import React, { useState, useContext, useCallback, useEffect } from 'react';
import TModal from './TModal';
import moment from 'moment';
import { modalController } from '@ionic/core';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/react';
import { TInput } from '../';
import '../../styles/components/PauseModal.scss';
import {
  leadsService,
  quickRepliesService,
  clientHoursService,
} from '../../services';
import { LeadContext, scrollToBottom } from '../../context/LeadContext';
import { AppContext } from '../../context/AppContext';
import { loadingIndicator, util, appNotification } from '../../core';
import { warning, flash } from 'ionicons/icons';
import TBanner from '../TBanner';
import TToggleItem from '../TToggleItem';
import TButtonItem from '../TButtonItem';
import QuickRepliesModal from './QuickRepliesModal';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  datePicker: {
    width: '100%',
  },
});

export interface PauseModalProps {
  isOpen?: boolean;
  onDidDismiss?: () => void;
}

const PauseModal: React.FC<PauseModalProps> = ({
  onDidDismiss,
  isOpen = false,
}) => {
  const { state, dispatch, createNewInternalMessage } = useContext(LeadContext);
  const appContext = useContext(AppContext);
  const { clientHours, lead } = state;
  const { user } = appContext.state;
  const timezone = leadsService.getTimezone(lead);
  const nowInDealerTimezone = moment.utc().tz(timezone); // Convert current time to dealer's timezone
  const defaultPauseDate = nowInDealerTimezone.utc().toISOString(); // Convert back to UTC
  const [pauseDate, setPauseDate] = useState<string>(defaultPauseDate);
  const classes = useStyles();
  const currentTimeInDealerTz = moment.tz(timezone);
  const [needsCall, setNeedsCall] = useState<boolean>(false);
  const [showQuickReplies, setShowQuickReplies] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState<any>();
  const [shortcodes, setShortcodes] = useState<any>();
  const [selectedShortCodeIndex, setSelectedShortCodeIndex] = useState(0);
  // Convert pauseDate to dealer's timezone for display
  const pauseDateInDealerTimezone = moment.utc(pauseDate).tz(timezone).format();
  const [displayPauseDate, setDisplayPauseDate] = useState<string>(
    pauseDateInDealerTimezone
  );

  const resizeInput = async () => {
    const textareaElement = document.querySelector('.pause-modal-message-input') as HTMLIonInputElement;
    if (textareaElement) {
      // Dispatch an input event to trigger autogrow
      const input = await textareaElement.getInputElement();
      const event = new Event('input', { bubbles: true });
      input.dispatchEvent(event);
    }
  }

  useEffect(() => {
    resizeInput();
  }, [message]);

  const fillShortcode = (e?: any) => {
    const code = e ?? shortcodes[selectedShortCodeIndex];
    const reply = appContext.state.shortcodes[code];
    if (reply?.text) {
      const newMessage = `${message.substring(
        0,
        message.lastIndexOf(':')
      )}${quickRepliesService.renderedMessageText(reply, lead)}`;
      setMessage(newMessage)
    } else {
      setMessage(e.detail?.value);
    }
    setShortcodes([]);
  };

  const doFillShortcode = (e: any) => {
    // If the Shift key is held down
    if (e.shiftKey) {
      const currentValue = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const newValue =
        currentValue.substring(0, cursorPosition) +
        '\n' +
        currentValue.substring(cursorPosition);
      setMessage(newValue);

      // Move cursor position after the newline
      setTimeout(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
      }, 0);
      return;
    }

    if (shortcodes?.length) {
      return fillShortcode();
    }
  };

  const searchShortcodes = async (value: string) => {
    const keys = Object.keys(appContext.state.shortcodes);
    const search = value.substring(value.lastIndexOf(':'));
    setShortcodes(keys.filter((it) => it.match(util.regexpEscape(search))));
  };

  const checkShortcodes = (value: string) => {
    const shouldShowShortcut = !!value.match(/(^|\s+):/);

    if (shouldShowShortcut) {
      searchShortcodes(value);
    } else {
      setShortcodes(null);
    }
    setMessage(value);
  };

  const doPauseLead = useCallback(async () => {
    if (!lead) {
      return null;
    }

    await loadingIndicator.create();
    try {
      const tzDate = moment.utc(pauseDate).tz(timezone);
      const formattedDate = tzDate.format('MM/DD/YY hh:mma zz');
      const updates = {
        id: lead.id,
        client: lead.client,
        pause_date: tzDate.toISOString(),
        pause_message: message,
        unpause_needs_call: needsCall,
      };
      const updated = await leadsService.update(updates, {
        successMessage: `Lead has been paused until ${formattedDate}.`,
      });
      dispatch({ type: 'set', value: { lead: updated } });

      await createNewInternalMessage({
        message: `${needsCall ? 'Needs Call Set; \n\n' : ''}Pause Date ${formattedDate}${message ? `; \n\nPause Message: ${message} \n` : ''}`,
      });

      setPauseDate(new Date().toISOString());
      setMessage('');

      util.delay(() => scrollToBottom?.(), 250);
      modalController.dismiss();
    } finally {
      loadingIndicator.dismiss();
    }
  }, [
    pauseDate,
    lead,
    message,
    createNewInternalMessage,
    dispatch,
    timezone,
    needsCall,
  ]);

  const pauseLead = useCallback(() => {
    if (validation?.during === false) {
      appNotification.alert({
        message:
          'Pause date is outside of business hours. Are you sure you want to schedule it?',
        header: 'Warning',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Continue',
            handler() {
              doPauseLead();
            },
          },
        ],
      });
    } else {
      doPauseLead();
    }
  }, [validation, doPauseLead]);

  const checkInsideBusinessHours = useCallback(() => {
    // Adjust this validation to use the displayPauseDate instead of pauseDate.
    setValidation(
      clientHoursService.isDuringBusinessHours(
        moment(displayPauseDate),
        clientHours,
        timezone
      )
    );
  }, [displayPauseDate, clientHours, timezone]);

  useEffect(() => checkInsideBusinessHours(), [checkInsideBusinessHours]);
  useEffect(() => {
    setDisplayPauseDate(moment.utc(pauseDate).tz(timezone).format()); // If you want to keep the original picked value for display
  }, [pauseDate, timezone]);

  const closeModal = useCallback(() => {
    onDidDismiss && onDidDismiss();
    setShowQuickReplies(false);
  }, [onDidDismiss]);

  return (
    <TModal
      isOpen={isOpen}
      title={`Pause ${state.lead?.first_name} ${state.lead?.last_name}`}
      onDidDismiss={closeModal}
      footer={
        <>
          {validation?.during === false && (
            <TBanner icon={warning} color='danger'>
              Pause date is outside of business hours
            </TBanner>
          )}
          <IonItem lines='none'>
            <IonButton
              disabled={user.is_staff && !message?.length}
              slot='end'
              color='success'
              fill='clear'
              size='default'
              onClick={pauseLead}
            >
              Pause
            </IonButton>
          </IonItem>
        </>
      }
    >
      <div className='pause-container'>
        <IonItem lines='full'>
          <DateTimePicker
            closeOnSelect={false}
            sx={{
              '& fieldset': { border: 'none' },
            }}
            className={classes.datePicker}
            value={moment.tz(pauseDate, timezone)}
            minDate={currentTimeInDealerTz}
            onChange={(newValue) => {
              // Convert the Date object back to a moment object in the dealer's timezone
              const dealerDate = moment.tz(newValue, timezone);
              // Convert to UTC for storage
              const utcDate = dealerDate.utc().format();
              setPauseDate(utcDate);
            }}
          />
        </IonItem>
        <IonItem lines='full' className='no-contain'>
          <IonLabel className='ion-multiline'>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand='block'
                    onClick={() => {
                      setPauseDate(moment().add(1, 'days').toISOString())
                    }}
                  >
                    1 Day
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand='block'
                    onClick={() => {
                      setPauseDate(moment().add(3, 'days').toISOString())
                    }}
                  >
                    3 Days
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand='block'
                    onClick={() => {
                      setPauseDate(moment().add(5, 'days').toISOString())
                    }}
                  >
                    5 Days
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand='block'
                    onClick={() => {
                      setPauseDate(moment().add(7, 'days').toISOString())
                    }}
                  >
                    1 Week
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    expand='block'
                    onClick={() =>
                      setPauseDate(moment().add(1, 'month').toISOString())
                    }
                  >
                    1 Month
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand='block'
                    onClick={() =>
                      setPauseDate(moment().add(3, 'month').toISOString())
                    }
                  >
                    3 Months
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand='block'
                    onClick={() =>
                      setPauseDate(moment().add(6, 'month').toISOString())
                    }
                  >
                    6 Months
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    expand='block'
                    onClick={() =>
                      setPauseDate(moment().add(12, 'month').toISOString())
                    }
                  >
                    1 Year
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonLabel>
        </IonItem>
        <TToggleItem
          className='no-contain'
          label='Needs Call'
          lines='full'
          checked={needsCall}
          onIonChange={(e: any) => setNeedsCall(e.detail.checked)}
          wrapLabel
        />
        <TButtonItem
          className='no-contain'
          color='secondary'
          onClick={() => setShowQuickReplies(true)}
        >
          <IonIcon icon={flash} slot='start' />
          Quick Replies
        </TButtonItem>
        <TInput
          className="pause-modal-message-input"
          autoCompletePosition='bottom'
          style={{ padding: '0 1rem' }}
          value={message}
          valueChanged={checkShortcodes}
          onEnterPressed={(e) => doFillShortcode(e)}
          multiline={true}
          autoGrow={true}
          autoGrowMaxHeight={260}
          autoCapitalize='on'
          results={shortcodes}
          onSelected={fillShortcode}
          onSelectedIndexChanged={setSelectedShortCodeIndex}
          spellcheck
          placeholder={`Message to send on ${moment
            .tz(displayPauseDate, timezone)
            .format('MMMM D, YYYY \\a\\t h:mma')}`}
        />
      </div>
      {showQuickReplies && (
        <QuickRepliesModal
          setMessage={setMessage}
          isOpen={true}
          onDidDismiss={() => setShowQuickReplies(false)}
        />
      )}
    </TModal>
  );
};

export default PauseModal;
